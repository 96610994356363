import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';

const tokenID = '1431441124645862250582583052259826260967744739352'


function App() {
  const [signature, setSignature] = useState("");
  const [signerAddress, setSignerAddress] = useState("");
  const [verificationResult, setVerificationResult] = useState("");
  const message = "Hello, World!";

  const signMessage = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        // Yêu cầu quyền truy cập ví MetaMask
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const [from] = await window.ethereum.request({ method: 'eth_accounts' });

        // Ký message
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [message, from],
        });

        // Hiển thị chữ ký
        setSignature(signature);

        // Xác minh chữ ký
        const recoveredAddress = ethers.verifyMessage(message, signature);
        setSignerAddress(recoveredAddress);

        // Kiểm tra địa chỉ khôi phục có khớp với địa chỉ ký không
        if (recoveredAddress.toLowerCase() === from.toLowerCase()) {
          setVerificationResult("Signature is valid and matches the signer address.");
        } else {
          setVerificationResult("Signature does not match the signer address.");
        }

      } catch (err) {
        console.error("Error signing message:", err);
      }
    } else {
      alert("Please install MetaMask to sign the message!");
    }
  };

  useEffect(() => {
    const socket = new WebSocket('wss://pf.predicthub.io/v1');

    socket.onopen = () => {
      console.log('WebSocket connected');
      const subscribeMessage = {
        type: "subscribe",
        id: "6UuDi1Mj08",
        params: [`${tokenID}@ordergraph`]
      };
      socket.send(JSON.stringify(subscribeMessage));
    };

    socket.onmessage = (event) => {
      console.log('Message from server', event.data);
      // setMessages(prevMessages => [...prevMessages, event.data]);
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    socket.onerror = (error) => {
      console.error('WebSocket error', error);
    };

    return () => {
      socket.close();
    };
  }, []);


  const handleSign = () => {
    const domain = {
      name: "Order Domain",
      version: "1",
      chainId: 421614, // Chain ID của Arbitrum Sepolia
      verifyingContract: "0x342a5d8fdb25704f1817f07445115a3adf22210d" // Địa chỉ smart contract liên quan đến order
    };
    
    const types = {
      Order: [
        { name: "salt", type: "uint256" },
        { name: "maker", type: "address" },
        { name: "signer", type: "address" },
        { name: "taker", type: "address" },
        { name: "tokenId", type: "uint256" },
        { name: "makerAmount", type: "uint256" },
        { name: "takerAmount", type: "uint256" },
        { name: "expiration", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "feeRateBps", type: "uint256" },
        { name: "side", type: "uint256" },
        { name: "signatureType", type: "uint8" }
      ]
    };
    
    const value = {
      salt: 1726219392022,
      maker: "0x301458d84615a59B98e1a7Ca37bb77E2cf63e59E",
      signer: "0x79A586bdbe817ED29B4541b3bb38e64959C8bF9e",  // Địa chỉ của người ký
      taker: "0x0000000000000000000000000000000000000000",
      tokenId: "1431441124645862250582583052259826260967744739352",
      makerAmount: "100000000",
      takerAmount: "100000000",
      expiration: 0,
      nonce: 0,
      feeRateBps: 1000,
      side: 0,
      signatureType: 2
    };

    async function signTypedData() {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = provider.getSigner();
    
      // Yêu cầu quyền truy cập ví MetaMask
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    
      // Ký TypedData theo chuẩn EIP-712
      const signature = await signer._signTypedData(domain, types, value);
    
      console.log("Chữ ký EIP-712:", signature);
      return signature;
    }
    
    // Gọi hàm ký
    signTypedData().catch(console.error);
    
    
  }


  return (
    <div style={{ padding: '20px' }}>
            <button onClick={handleSign}>Sign Message with MetaMask</button>

      {/* <h1>MetaMask Signature Verification</h1>
      <p>Message: {message}</p>
      <button onClick={signMessage}>Sign Message with MetaMask</button>
      <p>Signature: {signature}</p>
      <p>Signer Address: {signerAddress}</p>
      <p>Verification Result: {verificationResult}</p> */}
    </div>
  );
}

export default App;
